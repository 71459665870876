<template>
  <b-form @submit.prevent="modifierDevis()">
    <b-row>
      <b-col cols="6">
        <h3 class="mb-2">
          Devis N° {{ devis.code }}
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card title="Affreteur">
          <b-row>
            <b-col
              class="py-0 my-0"
              cols="12"
            >
              <b-form-group
                class="p-0 m-0 text-bold"
                label="Nom"
                label-for="hi-first-name"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ devis.affreteur.firstname }} {{ devis.affreteur.lastname }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              class="py-0 my-0"
              cols="12"
            >
              <b-form-group
                class="p-0 m-0"
                label="Email"
                label-for="hi-email"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ devis.affreteur.email }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              class="py-0 my-0"
              cols="12"
            >
              <b-form-group
                label="Téléphone"
                class="p-0 m-0"
                label-for="hi-number"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ devis.affreteur.telephone }}
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>
        </b-card>

      </b-col>
      <b-col cols="6">
        <b-card title="Moyen de transport">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Catégorie"
                class="pt-0 mt-0"
                label-for="hi-first-name"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge ">
                  CAMION CITERNE
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Type"
                label-for="hi-email"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  CITERNE POUR PULVERULENTS
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>

        </b-card>

      </b-col>

    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card title="Itinéraire">
          <b-row>
            <b-col
              class="py-0 my-0"
              cols="12"
            >
              <b-form-group
                class="p-0 m-0 text-bold"
                label="Départ"
                label-for="hi-first-name"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  <!-- KPASSE ( OUIDAH ) -->
                  {{ devis.ville_depart }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              class="py-0 my-0"
              cols="12"
            >
              <b-form-group
                class="p-0 m-0"
                label="Dte départ"
                label-for="hi-email"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ devis.offre.date_prev_depart }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              class="py-0 my-0"
              cols="12"
            >
              <b-form-group
                label="Destination"
                class="p-0 m-0"
                label-for="hi-number"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  <!-- FIFADJI ( COTONOU ) -->
                  {{ devis.ville_destination }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              class="py-0 my-0"
              cols="12"
            >
              <b-form-group
                label="Dte arrivée"
                class="p-0 m-0"
                label-for="hi-number"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ devis.offre.date_prev_livraison }}
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>
        </b-card>

      </b-col>
      <b-col cols="6">
        <b-card title="Marchandise">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Catégorie"
                class="pt-0 mt-0"
                label-for="hi-first-name"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge ">
                  <!-- {{ offre.categorie_marchandise.libelle }} -->

                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Type"
                label-for="hi-email"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ devis.marchandise }}
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Poids"
                label-for="hi-email"
                label-cols-md="4"
              >
                <b-input-group class="input-group-merge">
                  {{ devis.offre.poids_marchandise }} ({{ devis.unite_de_mesure }})
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>

        </b-card>

      </b-col>

    </b-row>

    <b-card title="Modifier le devis">
      <b-row>
        <b-col cols="6">
          <validation-provider
            #default="{ errors }"
            name="camion"
            rules="required"
          >
            <v-select
              id="moyens_transport"
              v-model="form.moyen_transport_id"
              label="camion"
              placeholder="Choix du camion"
              :state="errors.length > 0 ? false : null"
              :options="moyensTransport"
              :reduce="(moyensTransport) => moyensTransport.id"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <v-select
            v-model="form.transporteur_interne_id"
            label="nom"
            :options="transporteurInternes"
            placeholder="Choix du transporteur"
            :reduce="(transporteurInternes) => transporteurInternes.id"
          />
        </b-col>

        <b-col cols="6 mt-2">
          <b-form-input
            id="annee_experience"
            v-model="form.annee_experience"
            type="number"
            placeholder="Nombre d'année d'expérience"
          />
        </b-col>

        <b-col cols="6 mt-2">
          <b-form-group>
            <flat-pickr
              v-model="form.date_prev_depart"
              class="form-control"
              placeholder="Proposer la date de chargement"
              :config="config"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6 mt-1">
          <b-form-group>
            <flat-pickr
              v-model="form.date_prev_livraison"
              class="form-control"
              placeholder="Proposer la date de livraison"
              :config="config"
            />
          </b-form-group>
        </b-col>
        <b-col
          lg="6"
          class="my-1"
        >
          <b-row>
            <b-col md="4">
              <v-select
                v-model="form.devise_id"
                label="libelle"
                :options="devises"
                placeholder="Devise"
                :reduce="(devises) => devises.id"
              />
            </b-col>
            <b-col md="8">
              <b-form-input
                v-model="form.montant"
                type="number"
                placeholder="Prix"
              />
            </b-col>
          </b-row>

        </b-col>

        <!-- submit and reset -->
        <b-col
          md="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class=" float-right"
          >
            Modifier
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="secondary"
            class="mr-1 float-right"
          >
            Rafraichir
          </b-button>
        </b-col>

      </b-row>

    </b-card>

  </b-form>

</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ref, onMounted, reactive } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BFormGroup, BFormInput, BCard, BForm, BButton, BInputGroup, BRow, BCol,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'
// eslint-disable-next-line import/no-cycle
import useDevises from '@/services/admin/deviseservice'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

// eslint-disable-next-line import/no-extraneous-dependencies, import/extensions
import { French } from 'flatpickr/dist/l10n/fr.js'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,

    BForm,
    vSelect,
    BButton,
    flatPickr,

  },
  directives: {
    Ripple,

  },
  setup() {
    const {
      createDevis, getTransporteurs, transporteurInternes, getTransporteursMeans, moyensTransport,
      updateDevis, offreSuccess, getDevis, devis,
    } = useSocieteTransporteurs()
    const {
      devises, getDevises,
    } = useDevises()

    const devisCode = ref('')

    const form = reactive({
      moyen_transport_id: '',
      transporteur_interne_id: '',
      date_prev_depart: '',
      date_prev_livraison: '',
      devise_id: '',
      montant: '',
      offre_id: null,
      code: null,
      annee_experience: null,
      societe_transport_id: null,
      affreteur_id: null,
    })

    const editDevis = async code => {
      await getDevis(code).then(() => {
        form.montant = devis.value.montant
        form.code = devis.value.code
        form.societe_transport_id = devis.value.societe_transport_id
        form.transporteur_interne_id = devis.value.transporteur_interne_id
        form.moyen_transport_id = devis.value.moyen_transport_id
        form.date_prev_depart = devis.value.date_prev_depart
        form.date_prev_livraison = devis.value.date_prev_livraison
        form.annee_experience = devis.value.annee_experience
        form.devise_id = devis.value.devise_id
        form.affreteur_id = devis.value.affreteur_id

        // form.image = offre.value.image
      })
    }

    onMounted(() => {
      getDevises()
      getTransporteurs()
      getTransporteursMeans()

      if (router.currentRoute.params.code) {
        devisCode.value = router.currentRoute.params.code
        editDevis(devisCode.value)
      }
    })

    const selected = [{ title: 'Chox du camion' }]
    const option = [{ title: 'Chox du camion' }, { title: 'RB 4526 (Chassis: 12345678)' }, { title: 'RB 4567 (Chassis: 12345678)' }, { title: 'RB 4896 (Chassis: 12345678)' }]
    const date = null
    const dateDefault = null
    const timePicker = null
    const dateNtim = null
    const multiDate = null
    const rangeDate = null
    const humanDate = null
    const disableDate = null
    const inlineDate = null
    const means = moyensTransport.type_camion
    const { offre } = devis

    const storeDevis = async () => {
      form.offre_id = offre.value.id
      form.affreteur_id = offre.affreteur_id
      await createDevis({ ...form })
    }

    const modifierDevis = async () => {
      form.offre_id = devis.value.offre_id
      form.code = devis.value.code
      form.affreteur_id = devis.value.affreteur_id
      form.societe_transport_id = devis.value.societe_transport_id

      await updateDevis(form).then(() => {
        if (offreSuccess.value === true) {}
      })
    }
    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M Y H i',
      altInput: true,
      enableTime: true,
      dateFormat: 'Y-m-d H:i',
      locale: French, // locale for this instance only
    }
    return {
      config,
      date,
      dateDefault,
      timePicker,
      dateNtim,
      multiDate,
      rangeDate,
      humanDate,
      disableDate,
      inlineDate,
      selected,
      option,
      form,
      storeDevis,
      modifierDevis,
      offre,
      devises,
      transporteurInternes,
      moyensTransport,
      means,
      devis,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
@/services/admin/deviseService